define([
  'modules/common/components/component',

  'modules/pwa/events/worker/load',
  'modules/pwa/events/worker/sourcesUpdate',

], (
  Component, LoadEvent, SourcesUpdateEvent,
) => {
  var Component = Component.extend({

    worker: null,

    getWorker() {
      if (!this.worker) {
        throw new Error('Worker not registered');
      }
      return this.worker;
    },

    register(worker) {
      const self = this;
      this.worker = worker;

      navigator.serviceWorker.addEventListener('message', (e) => {
        if (e.data && e.data.event) {
          const eventName = e.data.event;
          self.handleMessage(eventName, e.data, e);
        } else {
          console.error(`Not able to handle webworker message: ${JSON.stringify(e.data)}`);
        }
      });

      worker.postMessage({
        event: 'check-source-update',
      });

      const event = new LoadEvent();
      event.trigger();
    },

    handleMessage(name, data, e) {
      if (name == 'check-source-update-response') {
        if (data.sourcesUpdated) {
          const event = new SourcesUpdateEvent();
          event.trigger();
        }
      } else {
        console.warn(`Unhandled message: ${JSON.stringify(data)}`);
      }
    },

  });

  return new Component();
});
