define([
  '@storekeeper/sentry',
  'application',
  'jquery',
  'underscore',
  'backbone',

  'module',
  'modules/common/components/module',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/components/errorReporter',
  'modules/shop.cash-register-retail/components/environment',
  'modules/shop.common/components/mode',
  'modules/common/components/moment',
  'modules/shop.common/components/browserCompatibility',

  'modules/shop.cash-register-retail/models/connection',
  'modules/shop.cash-register-retail/models/reactNativeWebViewHandler',

  'modules/upx/collections/users',

  'modules/upx/events/user/loggedOut',

  'modules/shop.cash-register-retail/contabs/connection',
  'modules/common/crontabs/idle',
  'modules/shop.cash-register-retail/contabs/notificationCheck',
  'modules/shop.cash-register-retail/contabs/activity',

  'modules/common/components/idle',
  'modules/common/components/locale',
  'modules/shop.common/components/onScreenKeyboard',
  'modules/shop.common/components/deviceConfig',

  // Settings
  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/shop.cash-register-retail/models/settings/keyboardLayout',

  // Default subscribers
  'modules/shop.cash-register-retail/subscribers/user/loggedOut',
  'modules/shop.cash-register-retail/subscribers/configure/menu',
  'modules/shop.cash-register-retail/subscribers/configure/device',
  'modules/shop.cash-register-retail/subscribers/profile/login',
  'modules/shop.cash-register-retail/subscribers/source/update',

  'modules/shop.cash-register-retail/subscribers/error/auth',
  'modules/admin/subscribers/route/home',
  'modules/shop.cash-register-retail/subscribers/connection/connection',
  'modules/shop.cash-register-retail/subscribers/session/logging',
  // Routers
  'modules/shop.cash-register-retail/routers/checkout',
  'modules/shop.cash-register-retail/routers/authentication',
  'modules/shop.cash-register-retail/routers/customerScreens',
  'modules/shop.cash-register-retail/routers/dailyReports',

  'modules/shop.cash-register-retail/routers/customers',
  'modules/shop.cash-register-retail/routers/products',
  'modules/shop.cash-register-retail/routers/orders',
  'modules/shop.cash-register-retail/routers/openOrders',
  'modules/shop.cash-register-retail/routers/newsLetters',
  'modules/shop.cash-register-retail/routers/calendar',
  'modules/shop.cash-register-retail/routers/stickerPrint',

  'modules/shop.cash-register-retail/routers/preview',
  'modules/shop.cash-register-retail/routers/posSettings',
  'modules/shop.cash-register-retail/routers/reload',
  'modules/shop.cash-register-retail/routers/hospitality',
  'modules/shop.cash-register-retail/routers/paymentScreens',
  'modules/shop.cash-register-retail/routers/repairs',
  'modules/shop.cash-register-retail/routers/onlineFood',

  // Collections and models for cleaning on startup
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/customerScreenData.js',

  'modules/shop.cash-register-retail/models/ipc/ipc',

  'modules/shop.cash-register-retail/components/info', // not used in the view but we need to make sure it`s loaded

  '@fortawesome/fontawesome-free/css/all.css',
  '@fortawesome/fontawesome-free/css/v4-shims.css',
  '@fortawesome/fontawesome-pro/css/all.css',
  '@fortawesome/fontawesome-pro/css/v4-shims.css',
], (
  SKSentry, App, $, _, Backbone,
  Module, AppModule, HistoryBreadcrumb, LogRelay, Environment, ModeComponent, Moment, BrowserCompatibility,
  ConnectionModel, ReactNativeWebViewHandler,
  UsersCollection,
  LoggedOutEvent,
  ConnectionCron, IdleCron, NotificationCheckCron, ActivityCron,
  IdleComponent, Locale, OnScreenKeyboardComponent, DeviceConfig,
  PointOfSaleSetting, KeyboardLayoutSetting,
  LoggedOutSubscriber, MenuSubscriber, DeviceSubscriber, ProfileLoginSubscriber, SourcesUpdateSubscriber,
  ErrorAuthSubscriber, HomeSubscriber, ConnectionSubscriber, SessionLoggingSubscriber,
  CheckoutRouter, AuthenticationRouter, CustomerScreensRouter, DailyReportsRouter,
  CustomersRouters, ProductsRouters, OrdersRouters, OpenOrdersRouters, NewsLettersRouters, CalendarRouters, StickerPrintRouters,
  PreviewRouter, PosSettingsRouter, ReloadRouter, HospitalityRouter, PaymentScreensRouter, RepairsRouter, OnlineFoodRouter,
  CurrentOrderItemCollection, PaymentMethodItemCollection, SelectedCustomerModel, CustomerScreenData,
  IPC,
) => {
  const module = new AppModule({

    id: 'modules/shop.cash-register-retail/application',

    initialize() {
      this.profileLoginDef = new $.Deferred();

      // Load customer-screen routes and subscribers
      if (Environment.isCustomerScreen()) {
        this.routerClasses = {
          customerScreen: CustomerScreensRouter,
        };

        this.profileLoginDef.resolve();
      }
      // Load normal routes and subscribers
      else {
        this.initializeRouters();
        this.initializeSubscribers();
      }
    },

    initializeRouters() {
      this.routerClasses = {
        checkout: CheckoutRouter,
        authentication: AuthenticationRouter,
        customerScreen: CustomerScreensRouter,
        dailyReports: DailyReportsRouter,
        customers: CustomersRouters,
        products: ProductsRouters,
        openOrders: OpenOrdersRouters,
        newsLetters: NewsLettersRouters,
        orders: OrdersRouters,
        calendar: CalendarRouters,
        stickerPrint: StickerPrintRouters,
        posSettingsRouter: PosSettingsRouter,
        previewRouter: PreviewRouter,
        reloadRouter: ReloadRouter,
        hospitalityRouter: HospitalityRouter,
        paymentScreensRouter: PaymentScreensRouter,
        RepairsRouter,
        OnlineFoodRouter,
      };
    },

    initializeSubscribers() {
      this.subscribers = {
        'user/loggedOut.js': new LoggedOutSubscriber(),
        'source/uUpdate.js': new SourcesUpdateSubscriber(),
        menu: new MenuSubscriber(),
        errorAuth: new ErrorAuthSubscriber(),
        connection: new ConnectionSubscriber(),
        sessionLogging: new SessionLoggingSubscriber(),
        home: new HomeSubscriber(),
        profileLogin: new ProfileLoginSubscriber({
          preloadDef: this.profileLoginDef,
        }),
        device: new DeviceSubscriber(),
      };
    },

    /**
         *  usage: put in config.json
         *
                "shop.cash-register-retail" : {
                    "no_pin_lock" : true,
                    "no_clean_checkout" : true,
                    "no_internet_check" : true
                }
         */
    async onStart(options) {
      const settings = App.settings['shop-cash-register'] || {};
      if (settings.default_locale) {
        const defaultLocale = settings.default_locale;
        Locale.setLocale(defaultLocale);
      } else {
        Locale.setLocale('nl');
      }
      Moment.locale(Locale.getLocale());

      SKSentry.Sentry.setTag('device_uuid', DeviceConfig.getDeviceUUID());
      if (App.settings && App.settings.upx && App.settings.upx.account) {
        SKSentry.Sentry.setTag('backend_account', App.settings.upx.account);
      }

      ModeComponent.register();

      if (ModeComponent.isInPreviewMode()) {
        await ModeComponent.setupPreview();
      }

      if (Environment.isCustomerScreen()) {
        // customer screen
        AppModule.prototype.onStart.call(this, options);

        // Set home of the application to the customer-screen route.
        HistoryBreadcrumb.setIndex('customer-screen');

        // Stop refresh of the session on the customer-screen route.
        if ('modules-upx-application' in App) {
          const upxSubModule = App['modules-upx-application'];
          if ('crontabs' in upxSubModule && 'refresh' in upxSubModule.crontabs) {
            const refreshCrontab = upxSubModule.crontabs.refresh;
            refreshCrontab.stop();
          }
        }
      } else {
        // cashier
        BrowserCompatibility.check();

        // Register components.
        IdleComponent.register();
        OnScreenKeyboardComponent.register({
          shrink_app_size: true,
          enabled_by_default: KeyboardLayoutSetting.getIdOrDefault() !== KeyboardLayoutSetting.TYPE_NONE,
          keyboardLayout: KeyboardLayoutSetting.getIdOrDefault(),
        });

        // Call AppModule onStart fn
        AppModule.prototype.onStart.call(this, options);

        // Register crontabs
        Module.crontabs = {
          idle: new IdleCron().start(),
          notification: new NotificationCheckCron().start(),
          activity: new ActivityCron().start(),
        };

        // Set home of the application
        HistoryBreadcrumb.setIndex('checkout');

        // Do cleaning up when needed
        const noCleanCheckout = 'no_clean_checkout' in settings && !!settings.no_clean_checkout;
        if (!noCleanCheckout) {
          CurrentOrderItemCollection.clear();
          PaymentMethodItemCollection.clear();
          SelectedCustomerModel.unload();
        } else {
          console.warn('Checkout cleaning on reload disabled using config.json');
        }

        // read default device uuid from config
        const deviceUuid = settings.device_uuid;
        if (deviceUuid) {
          DeviceConfig.setDefaultDeviceUuid(deviceUuid);
          SKSentry.Sentry.setTag('device_uuid', deviceUuid);
        }

        const noInternetCheck = 'no_internet_check' in settings && !!settings.no_internet_check;
        if (!noInternetCheck) {
          ConnectionModel.register();
          ConnectionModel.startupConnectionCheck();
          Module.crontabs.connection = new ConnectionCron().start();
        } else {
          console.warn('Internet check disabled using config.json');
        }

        if (ModeComponent.isInAppMode()) {
          ReactNativeWebViewHandler.register();
        }

        await IPC.setup();

        CustomerScreenData.resetData();

        // Waiting for the customer profile to load.
        if (UsersCollection.hasActiveUser()) {
          Backbone.Preloader.queue = Backbone.Preloader.queue || {};
          Backbone.Preloader.queue.cash_register_retail_data_preloader = this.profileLoginDef;

          if ('profile' in Backbone.Preloader.queue) {
            // reject if profile fails
            Backbone.Preloader.queue.profile
              .fail(this.profileLoginDef.reject);
          }
        } else {
          const ev = new LoggedOutEvent();
          ev.trigger();
        }
      }
    },

  });

  return module.register();
});
