define([
  'application',
  'modules/common/components/module',
  'modules/pwa/events/worker/registerFailed',
  'modules/pwa/component/worker',
],
(App, AppModule, RegisterEventFailed, Worker) => {
  const module = new AppModule({
    id: 'modules/pwa/application',

    onStart() {
      if (window.live) {
        console.warn('Web worker disabled in live mode');
      } else if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker.register('sw.js').then((registration) => {
            Worker.register(registration.active);
          }).catch((registrationError) => {
            const event = new RegisterEventFailed({
              error: registrationError,
            });
            event.trigger();
          });
        });
      } else {
        console.warn('No web worker support');
      }
    },
  });

  return module.register();
});
