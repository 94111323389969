define([
  'application',
  'module',
  'jquery',
  'underscore',
  'upx.modules/RelationsModule/models/MyProfile',
  'upx.modules/RelationsModule/models/Relation',
  'modules/profile/events/profile/beforeLoad',
  'modules/profile/events/profile/load',
  'modules/profile/events/profile/afterLoad',
  'modules/profile/events/profile/unload',
  'modules/upx/components/upx',
],
(App, Module, $, _, UpxModel, RelationModel, BeforeLoadEvent, LoadEvent, AfterLoadEvent, UnloadEvent, Upx) => {
  const model = UpxModel.extend({
    offline: true,

    idAttribute: 'fetchId',

    loaded: false,

    /**
             * Load the profile
             */
    load(user, options) {
      options = options || {};
      const self = this;
      const def = $.Deferred();

      if (_.has(options, 'skip_when_loaded') && !!options.skip_when_loaded && !!this.loaded) {
        return def.resolve();
      }

      this.set({ fetchId: 'modules/profile/models/profile' });

      if (!!user && user.get('rights') == 'user') {
        this.set({
          super_admin: true,
          relation_data: {
            name: user.get('name'),
            contact_person: {
              firstname: 'System',
              familyname: 'Administrator',
              image_url: 'images/default_avatar.png',
            },
          },
        });

        const event = new LoadEvent({
          profile: self,
        });
        event.trigger();

        if (event.subDef) {
          $.when(event.subDef.promise()).then(
            () => {
              self.loaded = true;
              const afterLoadEvent = new AfterLoadEvent({
                profile: self,
              });
              afterLoadEvent.trigger();
              def.resolve();
            },
            (resp) => {
              def.reject(resp);
            },
          );
        } else {
          self.loaded = true;
          const afterLoadEvent = new AfterLoadEvent({
            profile: self,
          });
          afterLoadEvent.trigger();
          def.resolve();
        }
      } else {
        const beforeLoadEvent = new BeforeLoadEvent();
        beforeLoadEvent.trigger();

        const fields = beforeLoadEvent.getFetchFields();

        $.when(this.fetch({ parameters: { fields } })).then(
          () => {
            const event = new LoadEvent({
              profile: self,
            });
            event.trigger();

            if (event.subDef) {
              $.when(event.subDef.promise()).then(
                () => {
                  self.loaded = true;
                  const afterLoadEvent = new AfterLoadEvent({
                    profile: self,
                  });
                  afterLoadEvent.trigger();
                  def.resolve();
                },
                (resp) => {
                  def.reject(resp);
                },
              );
            } else {
              self.loaded = true;
              const afterLoadEvent = new AfterLoadEvent({
                profile: self,
              });
              afterLoadEvent.trigger();
              def.resolve();
            }
          },
          () => {
            def.reject('Failed to load the profile');
          },
        );
      }

      return def.promise();
    },

    /**
             * Unload the profile
             */
    unload() {
      this.clear();
      this.getCacheStorage().cleanup(); // clean the cache to not load old profile

      const event = new UnloadEvent();
      event.trigger();

      this.loaded = false;
    },

    /**
             * List product addon types
             * For example:
             * - MobileAppModule::Register
             * - MobileSocialModule::BasicChat
             * @returns {Array}
             */
    listProductAddonTypes() {
      const productAddonTypes = [];

      $.each(this.getProducts(), (key, product) => {
        if (product.product_addon_types !== undefined) {
          $.each(product.product_addon_types, (key2, productAddonType) => {
            productAddonTypes.push(
              `${productAddonType.module_name}::${
                productAddonType.alias}`,
            );
          });
        }
      });

      return productAddonTypes;
    },

    /**
             * Check if a product addon type is available
             * @param module
             * @param addonTypeAlias
             * @returns {boolean}
             */
    hasProductAddonType(module, addonTypeAlias) {
      let hasProductAddonType = false;

      $.each(this.getProducts(), (key, product) => {
        if (product.product_addon_types !== undefined) {
          $.each(product.product_addon_types, (key2, productAddonType) => {
            if (module == productAddonType.module_name && addonTypeAlias == productAddonType.alias) {
              hasProductAddonType = true;
            }
          });
        }
      });

      return hasProductAddonType;
    },

    /**
             * Get full profile name
             * @returns {string}
             */
    getFullProfileName() {
      const profile = this.getRelationDataProfile();
      if (profile !== undefined && profile.contact_person !== undefined) {
        if (!!profile.contact_person.familyname_prefix && profile.contact_person.familyname_prefix.trim() !== '') {
          return `${profile.contact_person.firstname} ${profile.contact_person.familyname_prefix} ${profile.contact_person.familyname}`.trim();
        }
        return `${profile.contact_person.firstname} ${profile.contact_person.familyname}`.trim();
      }
      return '';
    },

    getProfileEmail() {
      if (this.has('relation_data_profile.contact_set.email')) {
        return this.get('relation_data_profile.contact_set.email');
      }
      return this.get('subuser.email');
    },

    /** w
             * Get product group id
             * @param module
             * @param typeAlias
             * @returns {*}
             */
    getProductGroupId(module, typeAlias) {
      const product = this.getProduct(module, typeAlias);
      return product.group_id;
    },

    /**
             * Get product
             * @param module
             * @param typeAlias
             * @returns {boolean}
             */
    getProduct(module, typeAlias) {
      let data = false;

      $.each(this.getProducts(), (key, product) => {
        if (module == product.product_type.module_name && typeAlias == product.product_type.alias) {
          data = product;
        }
      });

      return data;
    },

    /**
             * Get product with certain rights
             * @param module
             * @param typeAlias
             * @param rights group member type alias
             * @returns {boolean}
             */
    getProductWithMemberRights(module, typeAlias, rights) {
      let data = false;

      $.each(this.getProducts(), (key, product) => {
        if (module == product.product_type.module_name && typeAlias == product.product_type.alias) {
          if (product.membership !== undefined && product.membership.group_member_type.alias == rights) data = product;
        }
      });

      return data;
    },

    /**
             * List product names
             * For example:
             * - BillingModule::billing
             * - CrmModule::crm
             * @returns {Array}
             */
    listProductNames() {
      const names = [];

      $.each(this.getProducts(), (key, product) => {
        names.push(
          `${product.product_type.module_name}::${
            product.product_type.alias}`,
        );
      });

      return names;
    },

    /**
             * Has product
             * @param module
             * @param typeAlias
             * @returns {boolean}
             */
    hasProduct(module, typeAlias) {
      let hasProduct = false;

      $.each(this.getProducts(), (key, product) => {
        if (module == product.product_type.module_name && typeAlias == product.product_type.alias) {
          hasProduct = true;
        }
      });

      return hasProduct;
    },
    /**
             * if it has other product than
             * @param module
             * @param typeAlias
             * @returns {boolean}
             */
    hasOtherProductThan(module, typeAlias) {
      let hasProduct = false;

      $.each(this.getProducts(), (key, product) => {
        if (module != product.product_type.module_name || typeAlias != product.product_type.alias) {
          hasProduct = true;
        }
      });

      return hasProduct;
    },
    /**
             *
             * @returns {*|Array}
             */
    getProducts() {
      return this.get('products') || [];
    },
    /**
             * Returns if you are a member of a certain product
             * @param module
             * @param typeAlias
             * @returns {boolean}
             */
    isMemberOf(module, typeAlias) {
      let isMember = false;

      $.each(this.getProducts(), (key, product) => {
        if (module == product.product_type.module_name && typeAlias == product.product_type.alias) {
          if (product.membership !== undefined) {
            isMember = true;
          }
        }
      });

      return isMember;
    },

    /**
             * Check if we have any membership above a certain level
             * @param level
             * @returns {boolean}
             */
    hasLevelAbove(level) {
      let above = false;

      $.each(this.getProducts(), (key, product) => {
        if (product.membership !== undefined) {
          if (product.membership.group_member_type !== undefined
                            && product.membership.group_member_type.level > level) {
            above = true;
          }
        }
      });

      return above;
    },

    /**
             * Returns if you are a member of a product on a certain access level
             * @param module
             * @param typeAlias
             * @param memberType
             * @param requiredAddons
             * @returns {boolean}
             */
    isGroupTypeMemberOf(module, typeAlias, memberType, requiredAddons) {
      let membership = false;
      requiredAddons = requiredAddons || [];
      $.each(this.getProducts(), (key, product) => {
        if (product.product_type
                        && module == product.product_type.module_name && typeAlias == product.product_type.alias) {
          if (product.membership !== undefined
                            && product.membership.group_member_type !== undefined
                            && product.membership.group_member_type.alias == memberType) {
            if (requiredAddons.length != 0) {
              // check required addons
              if (product.product_addon_types && product.product_addon_types.length) {
                const requiredAddonsCopy = $.extend([], requiredAddons);
                // there are addons
                $.each(product.product_addon_types, function () {
                  const type = `${this.module_name}::${this.alias}`;
                  const i = requiredAddonsCopy.indexOf(type);
                  if (i != -1) {
                    requiredAddonsCopy.splice(i, 1);
                  }
                });

                if (requiredAddonsCopy.length == 0) {
                  membership = true;
                }
              }
            } else {
              // no addons required
              membership = true;
            }
          }
        }
        if (membership) {
          return false; // break loop, has it
        }
      });

      return membership;
    },

    /**
             * Get relation data profile
             * fallbacks to relation data if no profile set
             * @returns {*}
             */
    getRelationDataProfile() {
      return this.get('relation_data_profile') || this.get('relation_data');
    },

    /**
             * Get relation data profile
             * fallbacks to relation data if no profile set
             * @returns {*}
             */
    getProfileLetters(relation_data) {
      const data = relation_data || this.getRelationDataProfile();
      const contact_person = data.contact_person || {};
      let letters = '';

      if (contact_person.firstname) {
        letters += contact_person.firstname.charAt(0);
      }
      if (contact_person.familyname) {
        letters += contact_person.familyname.charAt(0);
      }
      letters = letters.toUpperCase();
      return letters;
    },
    /**
             * Get relation data profile
             * fallbacks to relation data if no profile set
             * @returns {*}
             */
    getAvatarUrl(relationData, profileData, fallbackUrl) {
      if (this.get('super_admin') === true) {
        return this.get('relation_data.contact_person.image_url');
      }

      if (fallbackUrl == undefined || fallbackUrl == false) {
        fallbackUrl = 'images/default_avatar.png';
      }
      let avatarUrl = fallbackUrl;

      relationData = relationData || this.get('relation_data');
      const url1 = this.getAvatarUrlFromRelationData(relationData);
      if (url1) {
        avatarUrl = url1;
      }

      profileData = profileData || this.get('relation_data_profile');
      const url2 = this.getAvatarUrlFromRelationData(profileData);
      if (url2) {
        avatarUrl = url2;
      }

      return avatarUrl;
    },
    /**
             *
             * @param relationData
             * @returns {boolean|string}
             */
    getAvatarUrlFromRelationData(relationData) {
      let avatarUrl = false;
      if (relationData) {
        if (relationData.image_url) {
          avatarUrl = Upx.getResourceUrl(relationData.image_url);
        } else if (relationData.business_data && relationData.business_data.logo_url) {
          avatarUrl = Upx.getResourceUrl(relationData.business_data.logo_url);
        } else if (relationData && relationData.contact_person && relationData.contact_person.image_url) {
          avatarUrl = Upx.getResourceUrl(relationData.contact_person.image_url);
        }
      }
      return avatarUrl;
    },

    /**
             *
             * @param relationData
             * @returns {boolean|string}
             */
    getRelationProfileDataModel() {
      const data = this.getRelationDataProfile();
      data.data_id = data.id;
      return new RelationModel(data);
    },

    getCountryIso2() {
      return this.get('relation_data.address_billing.country_iso2')
          || this.get('relation_data.business_data.country_iso2')
          || this.get('relation_data.contact_address.country_iso2');
    },
  });

  return new model();
});
