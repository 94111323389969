define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/layout/options',
  'modules/shop.common/components/floatingRegion',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/promisify',
  'magnific-popup',

], (
  $, _, Backbone, LayoutOptions, FloatingRegion,
  CashRegisterApi, Promisify,
) => Backbone.Marionette.LayoutView.extend({
  template: false,

  name: 'modules/mobile/views/popup',

  getName() {
    return this.name;
  },

  initialize(options) {
    options = options || {};
    this.options = options = $.extend(true, {},
      this.defaults,
      LayoutOptions.getData(this.getName()),
      options);
  },

  logOpenAction(type, otherData = {}) {
    CashRegisterApi.logAction('POPUP_OPEN', {
      ...otherData,
      type,
    });
  },

  startLoader() {
    // Setup loader
    const $content = $('.mfp-content');
    // Style "display:block" is needed to show the loader. without it its "display:none".
    const $loader = $('<div class="loader-wrapper" style="display: block;"><div class="loader"></div></div>');
    $content.prepend($loader);

    // Create and handle deferred
    const def = new $.Deferred();
    def.always(() => $loader.remove());

    return def;
  },

  getPopupId() {
    return `popup|${this.cid}`;
  },

  renderInFloatingRegion(def) {
    const id = this.getPopupId();
    def = def || new $.Deferred();
    this.closeDef = def;

    $.each(FloatingRegion.regions, (i, o) => {
      if (i.startsWith('popup|')) {
        FloatingRegion.cleanRegion(i);
      }
    });
    const region = FloatingRegion.getRegion(id);
    region.show(this);

    // mark so we can later find out if open,
    // this way we close the magnificPopup only when it includes this view
    this.$el.data('popupId', id);

    def.always(
      () => FloatingRegion.cleanRegion(id),
    );
    return def;
  },

  confirm() {
    if (this.closeDef && this.closeDef.state() === 'pending') {
      this.closeDef.resolve();
    }
    this.close();
  },

  confirmClicked: _.debounce(function () {
    this.confirm();
  }, 25),

  close() {
    if ($.magnificPopup.instance && $.magnificPopup.instance.isOpen) {
      const currentItem = $.magnificPopup.instance.currItem;
      if (currentItem && currentItem.inlineElement) {
        if (currentItem.inlineElement.data('popupId') === this.getPopupId()) {
          $.magnificPopup.close();
        }
      } else {
        console.warn(`Not correctly handled magnificPopup.close [${this.getPopupId()}]`);
        $.magnificPopup.close();
      }
    }
    if (this.closeDef && this.closeDef.state() === 'pending') {
      this.closeDef.reject();
    }
    this.triggerMethod('popup:closed');
  },

  closePopup() {
    this.close();
  },

  // Sometimes closing the popup logs you out
  closeClicked: _.debounce(function () {
    this.close();
  }, 100),

  onDestroy() {
    this.close();
  },

  onShow() {},

  openPopup({
    prependTo = null,
    removalDelay = 0,
    mainClass = 'mfp-fade',
  } = {}) {
    $.magnificPopup.open({
      items: [{
        src: this.$el.get(0),
        type: 'inline',
      }],
      removalDelay,
      mainClass,
      modal: true,
      prependTo,
    });
  },

  openPromise(...args) {
    const deferred = this.open(...args);
    return Promisify.deferredToPromise(deferred);
  },
}));
